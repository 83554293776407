import { cleanQueryParams } from '@/utils/cleanQueryParams';
import { abortController } from '@/utils/request/abort';

export const onRequestFulfilled = async (config: any) => {
  config.signal = abortController.signal;

  // Remove empty strings and undefined from query params
  config.params = cleanQueryParams(config.params);

  return config;
};
