import * as authApi from '@/api/auth';

export const useAuthStore = createGlobalState(() => {
  const state = useStorage<{ isAuthorized: boolean }>('authState', {
    isAuthorized: false,
  });

  const isAuthorized = computed(() => state.value.isAuthorized);

  const setIsAuthorized = (isAuthorized: boolean) => {
    state.value.isAuthorized = isAuthorized;
  };

  const getToken = async ({
    username,
    password,
  }: {
    username: string;
    password: string;
  }) => {
    try {
      const data = await authApi.getToken({
        username,
        password,
      });

      if (data && data.access) {
        setIsAuthorized(true);
      }
    } catch (error: any) {
      return Promise.reject(error);
    }
  };

  const logout = () => {
    authApi.memLogout();
    setIsAuthorized(false);
  };

  const requestPasswordReset = async (email: string) => {
    try {
      await authApi.requestPasswordReset(email);
    } catch (error: any) {
      return Promise.reject(error);
    }
  };

  const confirmPasswordReset = async ({
    uid,
    token,
    newPassword,
  }: {
    uid: string;
    token: string;
    newPassword: string;
  }) => {
    try {
      await authApi.confirmPasswordReset({ uid, token, newPassword });
    } catch (error: any) {
      return Promise.reject(error);
    }
  };

  const passwordChange = async (oldPassword: string, newPassword: string) => {
    try {
      await authApi.passwordChange(oldPassword, newPassword);
    } catch (error: any) {
      return Promise.reject(error);
    }
  };

  return {
    getToken,
    logout,
    isAuthorized,
    setIsAuthorized,
    requestPasswordReset,
    confirmPasswordReset,
    passwordChange,
  };
});
